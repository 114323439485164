#loading {
    display: block;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary0;
    .loader,
    .loader:before,
    .loader:after {
        background: #ffffff;
        animation: load1 1s infinite ease-in-out;
        width: 1em;
        height: 4em;
    }
    .loader {
        color: #ffffff;
        text-indent: -9999em;
        margin: 88px auto;
        position: absolute;
        left: 50%;
        top: 35%;
        font-size: 11px;
        transform: translate(-50%, -50%);
        animation-delay: -0.16s;
        &:before,
        &:after {
            position: absolute;
            top: 0;
            content: '';
        }
        &:before {
            left: -1.5em;
            animation-delay: -0.32s;
        }
        &:after {
            left: 1.5em;
        }
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
