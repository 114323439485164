:root {
    font-size: 16px;
}

* {
    padding: 0;
    margin: 0;
    font-weight: 100;
    text-decoration: none;
    list-style: none;
    font-family: $fontText;
    font-weight: 300;
    font-size: 0.875rem;
}

div,
p,
article,
aside,
section {
    box-sizing: border-box;
}

img {
    user-select: none;
}

::selection {
    color: white;
}

*:focus {
    outline: none;
}

html,
body {
    width: 100vw;
    height: 100vh;
}

body {
    max-height: 100vh;
    overflow: hidden;
}

button {
    cursor: pointer;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $secondary1 inset;
    -webkit-text-fill-color: white !important;
    -webkit-border-radius: 0;
}

heavy {
    font-weight: 900;
}

i{
    font-style: normal !important;
}

.information {
    color: $info;
    background-color: #BDE5F8;
}

.success {
    color: $success;
    background-color: #DFF2BF;
}

.warning {
    color: $warning;
    background-color: white;
    .warning_wrapper {
        display: flex;
        justify-content: space-around;
        .thumb-up,
        .thumb-down {
            display: block;
        }
    }
}

.error {
    color: $error;
    background-color: rgba($error, 0.1);
}

.hidden {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontTitle;
}

h1 {
    font-size: $h1;
}

h2 {
    font-size: $h2;
}

h3 {
    font-size: $h3;
}

h4 {
    font-size: $h4;
}

h5 {
    font-size: $h5;
}

h6 {
    font-size: $h6;
}

.blocker {
    display: none;
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110vw;
    height: 110vw;
    z-index: 0;
}
