#lang_switcher {
    position: absolute;
    z-index: 1;
    right: 10%;
    top: 5%;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .lang {
        background: none;
        color: white;
        text-align: center;
        padding: 7%;
        transition: all 0.2s ease;
        h2 {
            font-size: 1rem;
            line-height: 1rem;
        }
    }
    .sliding_selector {
        position: absolute;
        z-index: -1;
        left: 0;
        width: 51%;
        height: 100%;
        background: $primary0;
    }
    @include responsive(phone, landscape) {
        flex-direction: column;
        right: 5%;
    }
}
