/**/

//
// RESPONSIVE
//
// Device List : 
// 'desktop'   = Target screen with with without taking orientation in account
// 'tablet'    = Target tablet-size screen
// 'phone'     = Target phone-seze screen
// 'mobile'    = Target both tablet-size and phone-size screen
//
// Attr List by device :
//      Default : NULL      = Takes the biggest width of the device without taking orientation in account
//      Desktop : xlarge    = Takes _variables.scss => $xlarge_max_width
//                large     = Takes _variables.scss => $large_max_width
//                medium    = Takes _variables.scss => $medium_max_width
//                small     = Takes _variables.scss => $small_max_width
//                xsmall    = Takes _variables.scss => $xsmall_max_width
//      Tablet  : portrait  = Takes _variables.scss => $tablet_portrait_max_width 
//                landscape = Takes _variables.scss => $tablet_landscape_max_width
//      Phone   : portrait  = Takes _variables.scss => $phone_portrait_max_width 
//                landscape = Takes _variables.scss => $phone_landscape_max_width
/**/

@mixin responsive($device, $attr:NULL) {
    @if $device==desktop {
        @if $attr==NULL {
            $attr: 'xlarge';
        }

        @if $attr==xlarge {
            @media screen and (max-width: $xlarge_max_width) {
                @content;
            }
        }

        @else if $attr==large {
            @media screen and (max-width: $large_max_width) {
                @content;
            }
        }

        @else if $attr==medium {
            @media screen and (max-width: $medium_max_width) {
                @content;
            }
        }

        @else if $attr==small {
            @media screen and (max-width: $small_max_width) {
                @content;
            }
        }

        @else if $attr==xsmall {
            @media screen and (max-width: $xsmall_max_width) {
                @content;
            }
        }
    }

    @else if $device==mobile {
        @media screen and (max-width: $tablet_landscape_max_width) {
            @content;
        }
    }

    @else if $device==tablet {
        @if $attr==NULL {
            @media screen and (max-width: $tablet_landscape_max_width) {
                @content;
            }
        }

        @else if $attr==portrait {
            @media screen and (orientation: portrait) and (max-width: $tablet_portrait_max_width) {
                @content;
            }
        }

        @else if $attr==landscape {
            @media screen and (orientation: landscape) and (max-width: $tablet_landscape_max_width) {
                @content;
            }
        }
    }

    @else if $device==phone {
        @if $attr==NULL {
            @media screen and (max-width: $phone_landscape_max_width) {
                @content;
            }
        }

        @else if $attr==portrait {
            @media screen and (orientation: portrait) and (max-width: $phone_portrait_max_width) {
                @content;
            }
        }

        @else if $attr==landscape {
            @media screen and (orientation: landscape) and (max-width: $phone_landscape_max_width) {
                @content;
            }
        }
    }
}


/**/

//
// SKIN
//
// 
/**/

@mixin skin($color) {

    /*///// LOADING /////*/
    #loading {
        background: rgba($color, 1);
    }

    /*///// MAIN /////*/
    ::selection {
        background-color: $color;
    }

    /*///// LAYOUT /////*/
    #overlay {
        background-color: $color;
    }

    /*///// BACKGROUND /////*/
    .categories {
        .category {
            h2 {
                background-color: $color;
            }
        }
    }

    .outline {
        outline-color: $color;
    }

    /*///// ASIDE /////*/
    .avatar {
        border-color: $color;
    }

    .greeting,
    .greeting span {
        color: $color;
    }

    .info {
        color: $color;

        p:last-child {
            border-color: $color;
        }
    }

    /*///// TOOLTIP /////*/
    .tooltip_container {
        border-color: $color;
    }

    /*///// PAGINATION /////*/
    .page_number {
        color: $color;
        border-color: $color;
    }

    /*///// LIGHTBOX /////*/
    hr {
        border-color: $color;
    }

    .close:before,
    .close:after {
        background-color: $color;
    }

    .count {
        color: $color;
    }

    .finished {
        background-color: $color;
    }

    .next {
        background-color: $color;
    }

    .previous {
        background-color: $color;
    }

    /*///// LIGHTBOX IMAGE /////*/
    .image_gallery {
        border-color: $color;

        .selected {
            border-color: $color;

            .img_chosen {
                background-color: $color;
            }
        }

        .trash {
            background-color: $color;
        }
    }

    /*///// TEXT /////*/
    #textsColumn,
    #rssColumn {
        border: 1px solid $color;

        h2 {
            color: $color;
        }

        #texte_perso_textQueue_children,
        #texte_perso_rssQueue_children {
            &>div {
                p {
                    background: $color;
                }
            }
        }

        .buttons {
            .add_button {
                background: $color;
            }
        }

        .generalProperties {
            p {
                background: $color;
            }

            .container {
                border-bottom: 1px solid $color;
            }
        }
    }

    /*///// FILE INPUT /////*/
    .lb_container .lb_section .lb_right_side .modif_container form {
        input[type=file] {
            &+label {
                color: $color;
                border-color: $color;

                &:hover {
                    background-color: $color;
                }

                span {
                    color: $color;
                }
            }
        }
    }

    /*///// LIGHTBOX INFO /////*/
    fieldset {
        color: $color;
    }

    #info_perso {
        select {
            border-color: $color;
            outline-color: $color;

            option {
                border-color: $color;
            }
        }
    }

    #info_left {
        border-right-color: $color;
    }

    /*///// LIGHTBOX CHOICE /////*/
    .choice {

        input[type=radio],
        input[type=checkbox] {
            &:checked+.check {
                border-color: $color;

                &::before {
                    background-color: $color;
                }
            }

            &:checked+.check+label {
                border-color: $color;
            }

            &#bgColorPickerRadio {
                &:checked+.check+label>div.colorpicker {
                    border-color: $color;
                }
            }

            &+label {
                border-color: $color;
            }
        }

        &:hover>.check {
            border-color: $color;

            &::before {
                background-color: $color;
            }
        }

        .ti-trash {
            background-color: rgba($color, 0.5);

            &:hover {
                background-color: rgba($color, 1);
            }
        }
    }

    /*///// LIGHTBOX VIDEO /////*/
    #video_container {
        .switch {
            border-color: $color;

            &:hover {
                background: $color;
            }

            &.selected {
                background: $color;
            }
        }


        .video_selector {
            border-color: $color;
        }

        .random_video_cat,
        .video_cat {
            background-color: $color;
        }

        .random_video_single,
        .video_single {
            div {
                border-bottom-color: $color;
            }
        }

        .video_chosen {
            background-color: $color;
        }

        #video_custom_container {
            &>div {

                border-color: $color;

                &#video_random_wrapper {
                    button {
                        background-color: $color;
                    }
                }

                input[type=file] {
                    &+label {
                        color: $color;
                    }
                }

                .decline,
                .accept {
                    background-color: $color;
                }
            }
        }


        #count {
            border-color: $color;

        }
    }

    /*///// LAYOUT SLIDER /////*/
    #db_right {
        #slider_container {
            .owl-controls {
                .owl-dot {
                    background-color: $color;
                }
            }
        }
    }

    /*///// LIGHTBOX DYNAMIC /////*/
    #dynamics_container {
        form {
            .collection_list {
                .item {

                    .new,
                    .submited,
                    .hidden {
                        input[type=file] {
                            &+label {
                                border-color: $color;
                                background-color: $color;

                                &:hover {
                                    color: $color;
                                }
                            }
                        }

                        .beforeAfter,
                        .slideShow {
                            .slideContainer {
                                border-color: $color;

                                .imageContainer {
                                    .remove_image {
                                        background-color: rgba($color, 0.5);

                                        &:hover {
                                            background-color: rgba($color, 1);
                                        }
                                    }
                                }

                                .properties {
                                    border-color: $color;
                                }
                            }

                            .addContainer {
                                .add_slide {
                                    background-color: rgba($color, 0.5);

                                    &:hover {
                                        background-color: rgba($color, 1);
                                    }
                                }
                            }

                            .removeContainer {
                                background-color: rgba($color, 0.5);

                                &:hover {
                                    background-color: rgba($color, 1);
                                }
                            }
                        }

                        .propertyBackground {
                            border-color: $color;
                        }
                    }
                }
            }
        }

        table {
            tr {

                th,
                td {
                    border-color: $color;
                }
            }
        }

        #preview_container {
            border-color: $color;
        }
    }
}