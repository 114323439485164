@import '../../fonts/themify/themify.css';
@import '../../fonts/font-awesome/css/font-awesome.min.css';
@import '../../fonts/custom/icons.css';
@import url(https://fonts.googleapis.com/css?family=Istok+Web:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);
$fontTitle: 'Istok Web',
Helvetica,
Arial,
sans-serif;
$fontText: 'Roboto',
Helvetica,
Arial,
sans-serif;

.font_georgia {
    font-family: Georgia, serif;
}

.font_palatino {
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.font_times {
    font-family: "Times New Roman", Times, serif;
}

.font_arial {
    font-family: Arial, Helvetica, sans-serif;
}

.font_arial_black {
    font-family: "Arial Black", Gadget, sans-serif;
}

.font_comic {
    font-family: "Comic Sans MS", cursive, sans-serif;
}

.font_impact {
    font-family: Impact, Charcoal, sans-serif;
}

.font_lucida {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.font_tahoma {
    font-family: Tahoma, Geneva, sans-serif;
}

.font_trebuchet {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.font_verdana {
    font-family: Verdana, Geneva, sans-serif;
}

.font_courier {
    font-family: "Courier New", Courier, monospace;
}

.font_lucida_console {
    font-family: "Lucida Console", Monaco, monospace;
}
