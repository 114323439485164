label {
    color: $primary0;
}

input {
    background: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid white;
    margin-bottom: 5%;
    color: white;
    max-width: 70%;
    transition: all 0.2s ease;
    &:invalid {
        border-bottom-color: red;
    }
    &:valid {
        border-bottom-color: white;
    }
}

.checkbox-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
    background: $primary0;
    box-shadow: inset 0px 0px 0px 1px #fff;
}
